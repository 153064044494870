.title {
    font-size: 14px;
    font-family: PopinsSemi, Poppins;
    font-weight: 600;
    color: #2E384E;
}

.time {
    padding-top: 6px;
    font-size: 12px;
    font-family: Poppins-Regular, Poppins;
    font-weight: 400;
    color: #B8C0CB;
}

.main {
    background: #FFFFFF;
    box-shadow: 0px 6px 34px 0px rgba(174, 143, 143, 0.14);
    border-radius: 20px;

    @apply flex flex-col justify-between mt-[18px] p-[20px];

    .up {
        padding-bottom: 26px;

        .sale {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #708096;
        }

        .saleCount {
            margin-top: 3px;
            font-size: 24px;
            font-family: Manrope-Bold, Manrope;
            font-weight: bold;
            color: #2E384E;
        }

        .saleRate {
            margin-left: 12px;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #28C92B;
        }

        .info {
            margin-top: 16px;
            @apply flex justify-between;

            .countName {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #708096;
            }

            .count {
                font-size: 16px;
                font-family: Manrope-Bold, Manrope;
                font-weight: bold;
                color: #2E384E;
                margin-top: 2px;
            }

            .countAdd {
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                // color: #FF3E5F;
                color: #28C92B;
                margin-top: 4px;
            }
        }

        .down {
            .pic {
                @apply w-full;
            }
        }
    }
}